// CartPage.js

import React, { useState, useEffect, useCallback } from "react";
import { useCart } from "../CartContext/CartContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { jwtDecode } from "jwt-decode";
import "./CartPage.css";

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const { isAuthenticated, userRole, getToken } = useAuth();
  console.log("CartPage - isAuthenticated:", isAuthenticated);

  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [participationFormCompleted, setParticipationFormCompleted] =
    useState(null); // Initialize to null
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const initialOptions = {
    "client-id":
      "AZMzuQYJ6QBFjl9sx-IdI_2OaOHd7AhJuhJ1iTtFLdjqOBNrc5eg23vcMvvx7WOiizE3RHt610W7-OGH",
    currency: "USD",
    intent: "capture"
  };

  // Function to calculate total price of cart items
  const calculateTotal = useCallback(() => {
    return cartItems.reduce(
      (total, item) => total + parseFloat(item.price || 0),
      0
    );
  }, [cartItems]);

  // Function to format cart item details
  const formatCartItem = (item) => {
    const formatDateTimeFromString = (dateTimeString) => {
      if (!dateTimeString) return "No Date Provided";

      const date = new Date(dateTimeString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true
      };
      return date.toLocaleString(undefined, options);
    };

    const formattedDate = formatDateTimeFromString(item.start_time);
    const title = item.title || item.name || "No Title Provided";
    const imageUrl = item.image_url || "";

    return {
      title,
      formattedDate,
      imageUrl
    };
  };

  const handleJoinClassClick = async () => {
    if (!token) {
      console.error("No token available.");
      alert("Authentication required. Please sign in again.");
      return;
    }

    try {
      const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

      // Register each event in the cart
      const responses = await Promise.all(
        cartItems.map((item) =>
          axios.post(
            `${baseURL}/api/event-registrations`,
            {
              user_id: userId,
              event_id: item.id
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
        )
      );

      // Check if all registrations succeeded
      const allSucceeded = responses.every(
        (response) => response.status === 200
      );
      if (allSucceeded) {
        // Map cartItems to ensure 'title' is present
        const classesToStore = cartItems.map((item) => ({
          ...item,
          title: item.title || item.name || "No Title Provided"
        }));

        console.log("Storing registeredClasses:", classesToStore);
        localStorage.setItem(
          "registeredClasses",
          JSON.stringify(classesToStore)
        );
        clearCart();
        navigate("/registration-success");
      } else {
        throw new Error("Some registrations failed.");
      }
    } catch (error) {
      console.error("Registration error:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      alert(
        "There was an issue processing your registration. Please try again."
      );
    }
  };

  // Fetch token and userId on component mount
  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);

    if (retrievedToken) {
      try {
        const decodedToken = jwtDecode(retrievedToken);
        setUserId(decodedToken.user_id);
        console.log("Decoded Token:", decodedToken);
        console.log("User ID set:", decodedToken.user_id);
      } catch (decodeError) {
        console.error("Error decoding token:", decodeError);
        setUserId(null);
      }
    } else {
      setIsLoading(false); // No token, stop loading
    }
  }, [getToken]);

  // Fetch participation form status
  useEffect(() => {
    const fetchParticipationFormStatus = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_API_URL || "http://localhost:3000";
        const response = await axios.get(`${baseURL}/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const userData = response.data;
        setParticipationFormCompleted(userData.participation_form_completed);
      } catch (error) {
        console.error("Error fetching participation form status:", error);
      } finally {
        setIsLoading(false); // Data fetched, stop loading
      }
    };

    if (userId && token) {
      fetchParticipationFormStatus();
    }
  }, [userId, token]);

  // Handle loading state
  if (isLoading) {
    return (
      <div className="text-center">
        <p>Loading...</p>
      </div>
    );
  }

  // Handle unauthenticated state
  if (!isAuthenticated) {
    return (
      <div className="cart-page container">
        <h1>Your Cart</h1>
        <div className="mb-4 text-center">
          <p className="form-incomplete-message">
            Please sign in to proceed to checkout.
            <br />
            <span
              className="sign-in-link"
              onClick={() => navigate("/login")}
              style={{
                color: "#007bff",
                cursor: "pointer",
                textDecoration: "underline"
              }}
            >
              Click here to sign in.
            </span>
          </p>
        </div>
      </div>
    );
  }

  // Handle incomplete participation form
  if (participationFormCompleted === false) {
    return (
      <div className="text-center">
        <p>
          Please complete the participation form before proceeding to checkout.
        </p>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/participation-form")}
        >
          Go to Participation Form
        </button>
      </div>
    );
  }

  // Normalize role string to lowercase for case-insensitive comparison
  const normalizedRole = userRole ? userRole.toLowerCase() : "";

  // Define role-based access
  const isSDP = normalizedRole === "self determination program (sdp)";
  const isStaff = normalizedRole === "staff";
  const isAdmin = normalizedRole === "administrator";
  const canCheckoutWithoutPayment = isSDP || isStaff || isAdmin;
  const canUsePaymentOptions = isStaff || isAdmin || normalizedRole === "user";

  // Check if there are items with price > 0
  const hasPaidItems = cartItems.some(
    (item) => parseFloat(item.price || 0) > 0
  );

  // Render the cart page when everything is ready
  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="cart-page container">
        <h1>Your Cart</h1>
        <div>
          <ul className="cart-list">
            {cartItems.map((item, index) => {
              const { title, formattedDate, imageUrl } = formatCartItem(item);
              return (
                <li key={index} className="cart-item">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt={title}
                      className="cart-item-image"
                    />
                  )}
                  <div className="cart-item-details">
                    <div className="cart-item-name">{title}</div>
                    <div className="cart-item-date">{formattedDate}</div>
                    {/* Remove Price Display */}
                    {/* <div className="cart-item-price">
                      {price > 0 ? `$${price.toFixed(2)}` : "Free"}
                    </div> */}
                  </div>
                  <div className="cart-item-actions">
                    <button
                      className="btn btn-danger remove-btn"
                      onClick={() => removeFromCart(index)}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>

          {/* Conditional Rendering of Checkout Buttons */}
          {(canCheckoutWithoutPayment ||
            (canUsePaymentOptions && hasPaidItems)) && (
            <div className="text-center mt-4">
              {/* "Checkout without Payment" Button for staff and administrator */}
              {canCheckoutWithoutPayment && (
                <button
                  className="btn btn-success mb-2"
                  onClick={handleJoinClassClick}
                >
                  Checkout without Payment
                </button>
              )}

              {/* Payment Options for users who can use them */}
              {canUsePaymentOptions && hasPaidItems && (
                <>
                  <h3>Total: ${calculateTotal().toFixed(2)}</h3>
                  <div
                    className="buttons-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "20px 0"
                    }}
                  >
                    {/* PayPal Checkout */}
                    <div style={{ width: "50%" }}>
                      <PayPalButtons
                        style={{
                          layout: "horizontal",
                          height: 50
                        }}
                        createOrder={(data, actions) => {
                          const totalAmount = calculateTotal().toFixed(2); // Ensure two decimal places
                          console.log("Total Amount for PayPal:", totalAmount);
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: totalAmount
                                }
                              }
                            ]
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order
                            .capture()
                            .then(function (details) {
                              console.log(
                                "PayPal Payment Successful:",
                                details
                              );
                              // Optionally, navigate to a success page or update state
                              navigate("/registration-success");
                            })
                            .catch((err) => {
                              console.error(
                                "Error capturing PayPal order:",
                                err
                              );
                              alert(
                                "There was an issue processing your PayPal payment. Please try again."
                              );
                            });
                        }}
                        onError={(err) => {
                          console.error("PayPal Checkout Error:", err);
                          alert(
                            "There was an issue processing your PayPal payment. Please try again."
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-2 mb-3">
                    <hr style={{ width: "40%" }} />
                    <span style={{ padding: "0 10px" }}>or</span>
                    <hr style={{ width: "40%" }} />
                  </div>
                  {/* Stripe Checkout Button */}
                  <button
                    className="stripe-button btn btn-primary"
                    onClick={() =>
                      navigate("/stripeCheckout", {
                        state: {
                          userId,
                          eventId: cartItems.map((item) => item.id)
                        }
                      })
                    }
                  >
                    Checkout with{" "}
                    <span className="stripe-word">Credit Card</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default CartPage;
