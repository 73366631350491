import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Form,
  Table,
  InputGroup,
  FormControl,
  Alert
} from "react-bootstrap";
import "./EditClasses.css"; // Ensure you have .semi-gray styling

function EditClasses() {
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Title Dropdown
  const [selectedTitle, setSelectedTitle] = useState("");

  // Search & Month
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  // Group expansions
  const [expandedGroups, setExpandedGroups] = useState({});

  // Modal states for editing
  const [showModal, setShowModal] = useState(false);
  const [editClassData, setEditClassData] = useState(null);
  const [applyToAll, setApplyToAll] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const navigate = useNavigate();

  //---------------------------------------------------
  // 1) Fetch classes on mount
  //---------------------------------------------------
  useEffect(() => {
    const fetchClasses = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Not authenticated.");
        setLoading(false);
        return;
      }
      try {
        const res = await axios.get(
          "https://www.ptemployment.org/api/classes/class-schedules",
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        setClasses(res.data);
      } catch (err) {
        setError("Failed to fetch classes. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchClasses();
  }, []);

  //---------------------------------------------------
  // 2) Format date/time in America/New_York
  //---------------------------------------------------
  const formatDisplayDateTime = (timeString) => {
    return moment
      .utc(timeString)
      .tz("America/New_York")
      .format("MMMM Do, YYYY, h:mm A");
  };

  const displayMonth = (yearMonthString) => {
    return moment(yearMonthString, "YYYY-MM").format("MMMM YYYY");
  };

  const getYearMonth = (timeString) => {
    return moment.utc(timeString).tz("America/New_York").format("YYYY-MM");
  };

  //---------------------------------------------------
  // 3) Build list of available months
  //---------------------------------------------------
  const availableMonths = useMemo(() => {
    const monthsSet = new Set();
    classes.forEach((c) => {
      monthsSet.add(getYearMonth(c.start_time));
    });
    return Array.from(monthsSet).sort();
  }, [classes]);

  //---------------------------------------------------
  // 4) Build list of all titles
  //---------------------------------------------------
  const allTitles = useMemo(() => {
    const titlesSet = new Set();
    classes.forEach((c) => {
      if (c.title) titlesSet.add(c.title);
    });
    return Array.from(titlesSet).sort();
  }, [classes]);

  //---------------------------------------------------
  // 5) Filter logic
  //---------------------------------------------------
  useEffect(() => {
    let updated = [...classes];

    // a) Filter by selectedTitle
    if (selectedTitle) {
      updated = updated.filter((evt) => evt.title === selectedTitle);
    }

    // b) Filter by month
    if (selectedMonth) {
      updated = updated.filter(
        (evt) => getYearMonth(evt.start_time) === selectedMonth
      );
    }

    // c) Filter by searchTerm
    if (searchTerm.trim() !== "") {
      const lower = searchTerm.toLowerCase();
      updated = updated.filter((evt) => {
        const combined = [
          evt.title,
          evt.description,
          evt.price ? evt.price.toString() : "",
          formatDisplayDateTime(evt.start_time),
          formatDisplayDateTime(evt.end_time)
        ]
          .join(" ")
          .toLowerCase();

        return combined.includes(lower);
      });
    }

    setFilteredClasses(updated);
  }, [classes, selectedTitle, selectedMonth, searchTerm]);

  //---------------------------------------------------
  // 6) Group by title
  //---------------------------------------------------
  const groupedClasses = useMemo(() => {
    const map = {};
    filteredClasses.forEach((evt) => {
      if (!map[evt.title]) map[evt.title] = [];
      map[evt.title].push(evt);
    });
    return map;
  }, [filteredClasses]);

  //---------------------------------------------------
  // 7) Expand/collapse group
  //---------------------------------------------------
  const toggleGroup = (title) => {
    setExpandedGroups((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  //---------------------------------------------------
  // 8) Edit button: open modal
  //---------------------------------------------------
  const handleEditClick = (classItem) => {
    setShowModal(true);
    setApplyToAll(false);
    setImageFile(null);

    const displayStart = formatDisplayDateTime(classItem.start_time);
    const displayEnd = formatDisplayDateTime(classItem.end_time);

    setEditClassData({
      id: classItem.id,
      originalTitle: classItem.title,
      title: classItem.title,
      description: classItem.description || "",
      price: classItem.price || "",
      hours: classItem.hours || "",
      display_start: displayStart,
      display_end: displayEnd,
      image_url: classItem.image_url || ""
    });
  };

  //---------------------------------------------------
  // 9) Close modal
  //---------------------------------------------------
  const handleCloseModal = () => {
    setShowModal(false);
    setEditClassData(null);
  };

  //---------------------------------------------------
  // parse user-friendly -> UTC
  //---------------------------------------------------
  const parseDisplayDateTimeToUTC = (displayStr) => {
    const parsed = moment.tz(
      displayStr,
      "MMMM Do, YYYY, h:mm A",
      "America/New_York"
    );
    if (!parsed.isValid()) {
      return null;
    }
    return parsed.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  };

  //---------------------------------------------------
  // 10) Save changes
  //---------------------------------------------------
  const handleSaveChanges = async () => {
    if (!editClassData) return;

    const token = localStorage.getItem("token");
    if (!token) {
      alert("Not authenticated.");
      return;
    }

    // Convert time -> UTC
    const start_time_utc = parseDisplayDateTimeToUTC(
      editClassData.display_start
    );
    const end_time_utc = parseDisplayDateTimeToUTC(editClassData.display_end);
    if (!start_time_utc || !end_time_utc) {
      alert("Invalid date/time format.");
      return;
    }

    try {
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` }
      };

      if (!applyToAll) {
        // Full update
        const formData = new FormData();
        formData.append("title", editClassData.title);
        formData.append("description", editClassData.description);
        formData.append(
          "price",
          editClassData.price === "" ? "" : editClassData.price
        );
        formData.append(
          "hours",
          editClassData.hours === "" ? "" : editClassData.hours
        );
        formData.append("start_time", start_time_utc);
        formData.append("end_time", end_time_utc);
        formData.append("image_url", editClassData.image_url || "");

        if (imageFile) {
          formData.append("image", imageFile);
        }

        await axios.put(
          `https://www.ptemployment.org/api/classes/class-schedules/${editClassData.id}`,
          formData,
          axiosConfig
        );
      } else {
        // Partial update for each event with same originalTitle
        const groupTitle = editClassData.originalTitle;
        const group = groupedClasses[groupTitle] || [];

        for (const item of group) {
          const partialForm = new FormData();
          partialForm.append("title", editClassData.title);
          partialForm.append("description", editClassData.description);

          if (imageFile) {
            partialForm.append("image_url", "");
            partialForm.append("image", imageFile);
          } else {
            partialForm.append("image_url", item.image_url || "");
          }
          partialForm.append("price", item.price === null ? "" : item.price);
          partialForm.append("hours", item.hours === null ? "" : item.hours);
          partialForm.append("start_time", item.start_time);
          partialForm.append("end_time", item.end_time);

          await axios.put(
            `https://www.ptemployment.org/api/classes/class-schedules/${item.id}`,
            partialForm,
            axiosConfig
          );
        }
      }

      alert("Changes saved successfully!");
      handleCloseModal();

      // Re-fetch
      const refreshed = await axios.get(
        "https://www.ptemployment.org/api/classes/class-schedules",
        axiosConfig
      );
      setClasses(refreshed.data);
    } catch (err) {
      console.error("Error updating classes:", err);
      alert("Failed to update. Please try again.");
    }
  };

  //---------------------------------------------------
  // 11) Delete an event
  //---------------------------------------------------
  const handleDelete = async (eventId) => {
    const confirmDel = window.confirm(
      "Are you sure you want to delete this event?"
    );
    if (!confirmDel) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Not authenticated.");
        return;
      }
      await axios.delete(
        `https://www.ptemployment.org/api/classes/class-schedules/${eventId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      alert("Event deleted successfully.");
      setClasses((prev) => prev.filter((evt) => evt.id !== eventId));
    } catch (err) {
      console.error("Error deleting event:", err);
      alert("Failed to delete event. Please try again.");
    }
  };

  //---------------------------------------------------
  // 12) Toggle "Hide" checkbox
  //---------------------------------------------------
  const handleToggleHidden = async (classItem) => {
    const newValue = !classItem.is_hidden;
    const confirmAction = window.confirm(
      newValue
        ? "Are you sure you want to hide this class from the Services page?"
        : "Are you sure you want to make this class visible again?"
    );
    if (!confirmAction) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Not authenticated.");
        return;
      }
      const partialForm = new FormData();
      partialForm.append("is_hidden", newValue ? "true" : "false");

      // Keep original fields
      partialForm.append("title", classItem.title);
      partialForm.append("description", classItem.description || "");
      partialForm.append("price", classItem.price || "");
      partialForm.append("hours", classItem.hours || "");
      partialForm.append("start_time", classItem.start_time);
      partialForm.append("end_time", classItem.end_time);
      partialForm.append("image_url", classItem.image_url || "");

      await axios.put(
        `https://www.ptemployment.org/api/classes/class-schedules/${classItem.id}`,
        partialForm,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      // Update local state
      setClasses((prev) =>
        prev.map((evt) =>
          evt.id === classItem.id ? { ...evt, is_hidden: newValue } : evt
        )
      );
    } catch (err) {
      console.error("Error toggling hidden state:", err);
      alert("Failed to toggle hidden state. Please try again.");
    }
  };

  //---------------------------------------------------
  // Render
  //---------------------------------------------------
  if (loading) {
    return (
      <div className="container mt-5">
        <h4>Loading classes...</h4>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {/* Back to Profile Button */}
      <Button
        variant="secondary"
        className="mb-3"
        onClick={() => navigate("/userProfile")}
      >
        Back to Profile
      </Button>

      <h1 className="mb-3 text-primary">Edit Classes</h1>

      {/* Friendly user note at top */}
      <Alert variant="info" className="mb-4">
        <h4 className="mb-3">Welcome to the Edit Classes Page!</h4>
        <p>
          Here you can manage all of your classes easily. Use the dropdowns
          below to <strong>filter by Class Title</strong>
          and then <strong>filter again by Month</strong> if needed.
        </p>
        <p>
          You can <strong>edit</strong> a single class's time/date/price/hours,
          or use <em>“Apply changes to all classes”</em> to apply only the
          changes you made (like the Title or Description) to every class with
          the same title.
        </p>
        <p>
          You can also <strong>delete</strong> any class, or
          <strong> hide</strong> it from the public “Services” page by checking
          the “Hide?” box. This is useful for classes offered only at certain
          times of the year. The row will be slightly grayed out, but still
          accessible if you want to unhide it later.
        </p>
      </Alert>

      {/* Filters */}
      <div className="d-flex mb-4" style={{ gap: "10px", flexWrap: "wrap" }}>
        {/* Title Dropdown */}
        <Form.Select
          style={{ maxWidth: "200px" }}
          value={selectedTitle}
          onChange={(e) => setSelectedTitle(e.target.value)}
        >
          <option value="">All Titles</option>
          {allTitles.map((title) => (
            <option key={title} value={title}>
              {title}
            </option>
          ))}
        </Form.Select>

        {/* Search Input */}
        <InputGroup style={{ maxWidth: "300px" }}>
          <FormControl
            placeholder="Search by title, description, price..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        {/* Month Dropdown */}
        <Form.Select
          style={{ maxWidth: "250px" }}
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option value="">All Months</option>
          {availableMonths.map((m) => (
            <option key={m} value={m}>
              {displayMonth(m)}
            </option>
          ))}
        </Form.Select>
      </div>

      {filteredClasses.length === 0 && (
        <div className="alert alert-info">
          No classes found for your filters.
        </div>
      )}

      {Object.entries(
        filteredClasses.reduce((acc, evt) => {
          acc[evt.title] = acc[evt.title] || [];
          acc[evt.title].push(evt);
          return acc;
        }, {})
      ).map(([title, items]) => {
        const firstItem = items[0];
        const isExpanded = expandedGroups[title] || false;
        const additionalCount = items.length - 1;

        return (
          <div key={title} className="mb-4">
            <Table bordered hover responsive>
              <thead className="table-light">
                <tr>
                  <th>Title</th>
                  <th>Start (NY Time)</th>
                  <th>End (NY Time)</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Hours</th>
                  <th>Image</th>
                  <th>Hide?</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* Main row */}
                <tr className={firstItem.is_hidden ? "semi-gray" : ""}>
                  <td>{title}</td>
                  <td>{formatDisplayDateTime(firstItem.start_time)}</td>
                  <td>{formatDisplayDateTime(firstItem.end_time)}</td>
                  <td>{firstItem.description || "N/A"}</td>
                  <td>{firstItem.price ? `$${firstItem.price}` : "N/A"}</td>
                  <td>{firstItem.hours || "N/A"}</td>
                  <td>
                    {firstItem.image_url ? (
                      <img
                        src={firstItem.image_url}
                        alt="Event"
                        style={{ maxWidth: "80px", maxHeight: "60px" }}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  {/* Hide Checkbox */}
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={!!firstItem.is_hidden}
                      onChange={() => handleToggleHidden(firstItem)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEditClick(firstItem)}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(firstItem.id)}
                      style={{ marginLeft: "5px" }}
                    >
                      Delete
                    </Button>{" "}
                    {items.length > 1 && (
                      <Button
                        variant="info"
                        size="sm"
                        style={{ marginLeft: "5px" }}
                        onClick={() =>
                          setExpandedGroups((prev) => ({
                            ...prev,
                            [title]: !prev[title]
                          }))
                        }
                      >
                        {isExpanded ? "Hide" : `+${additionalCount} more`}
                      </Button>
                    )}
                  </td>
                </tr>

                {/* Additional items */}
                {isExpanded &&
                  items.slice(1).map((item) => (
                    <tr
                      key={item.id}
                      className={item.is_hidden ? "semi-gray" : ""}
                    >
                      <td />
                      <td>{formatDisplayDateTime(item.start_time)}</td>
                      <td>{formatDisplayDateTime(item.end_time)}</td>
                      <td>{item.description || "N/A"}</td>
                      <td>{item.price ? `$${item.price}` : "N/A"}</td>
                      <td>{item.hours || "N/A"}</td>
                      <td>
                        {item.image_url ? (
                          <img
                            src={item.image_url}
                            alt="Event"
                            style={{ maxWidth: "80px", maxHeight: "60px" }}
                          />
                        ) : (
                          "No Image"
                        )}
                      </td>
                      {/* Hide Checkbox */}
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={!!item.is_hidden}
                          onChange={() => handleToggleHidden(item)}
                        />
                      </td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </Button>{" "}
                        <Button
                          variant="danger"
                          size="sm"
                          style={{ marginLeft: "5px" }}
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        );
      })}

      {/* EDIT MODAL */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editClassData
              ? `Editing Class ID: ${editClassData.id}`
              : "Editing Class"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editClassData && (
            <Form>
              {/* Title */}
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={editClassData.title}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      title: e.target.value
                    })
                  }
                />
              </Form.Group>

              {/* Start (NY Time) */}
              <Form.Group className="mb-3">
                <Form.Label>Start (NY Time)</Form.Label>
                <Form.Control
                  type="text"
                  value={editClassData.display_start}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      display_start: e.target.value
                    })
                  }
                />
                <Form.Text className="text-muted">
                  E.g. "January 25th, 2025, 10:30 AM"
                </Form.Text>
              </Form.Group>

              {/* End (NY Time) */}
              <Form.Group className="mb-3">
                <Form.Label>End (NY Time)</Form.Label>
                <Form.Control
                  type="text"
                  value={editClassData.display_end}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      display_end: e.target.value
                    })
                  }
                />
                <Form.Text className="text-muted">
                  E.g. "January 25th, 2025, 12:00 PM"
                </Form.Text>
              </Form.Group>

              {/* Description */}
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={editClassData.description}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      description: e.target.value
                    })
                  }
                />
              </Form.Group>

              {/* Price */}
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  min="0"
                  value={editClassData.price}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      price: e.target.value
                    })
                  }
                />
              </Form.Group>

              {/* Hours */}
              <Form.Group className="mb-3">
                <Form.Label>Hours</Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  min="0"
                  value={editClassData.hours}
                  onChange={(e) =>
                    setEditClassData({
                      ...editClassData,
                      hours: e.target.value
                    })
                  }
                />
              </Form.Group>

              {/* Current Image */}
              {editClassData.image_url && (
                <Form.Group className="mb-3">
                  <Form.Label>Current Image</Form.Label>
                  <div>
                    <img
                      src={editClassData.image_url}
                      alt="Current"
                      style={{ maxWidth: "200px", maxHeight: "150px" }}
                    />
                  </div>
                </Form.Group>
              )}

              {/* New Image */}
              <Form.Group className="mb-3">
                <Form.Label>Upload New Image (optional)</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImageFile(e.target.files?.[0] || null)}
                />
              </Form.Group>

              {/* Apply to All */}
              <Form.Check
                type="checkbox"
                className="mb-2"
                label="Apply changes (Title/Description/Image) to all events with this Title"
                checked={applyToAll}
                onChange={(e) => setApplyToAll(e.target.checked)}
              />
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditClasses;
